import request from '@/utils/request'

// 按分页获取所有用户日志
export function getUserLog(reqParams) {
  return request({
    url: '/userlog/get/page',
    method: 'get',
    params: reqParams
  })
}
