<template>
  <div class="app-container">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="用户管理日志" name="用户管理日志">
        <user-log></user-log>
      </el-tab-pane>
      <!-- <el-tab-pane label="系统管理日志" name="系统管理日志"></el-tab-pane> -->
      <el-tab-pane label="网关日志" name="网关日志">
        <gateway-log></gateway-log>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import gatewayLog from './components/gatewayLog'
import userLog from './components/userLog'

export default {
  components: {
    'gateway-log': gatewayLog,
    'user-log': userLog
  },
  data() {
    return {
      activeName: '用户管理日志'
    }
  },
  methods: {
    handleClick(tab, event) {
    //   if (tab.name === '系统管理日志') {
    //     this.moudleList = ['菜单配置', '权限分配']
    //   } else {
    //     this.moudleList = ['账号管理', '角色管理', '权限分配']
    //   }
    }
  }
}
</script>
