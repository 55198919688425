<template>
  <div>
    <el-form ref="searchForm" :model="searchForm" inline size="small">
      <el-form-item label="用户名">
        <el-input v-model="searchForm.username" placeholder="用户名查询"></el-input>
      </el-form-item>
      <el-form-item label="IP">
        <el-input v-model="searchForm.ip" placeholder="IP地址查询"></el-input>
      </el-form-item>
      <el-form-item label="URL">
        <el-input v-model="searchForm.url" placeholder="URL查询"></el-input>
      </el-form-item>
      <el-form-item label="开始时间">
        <el-date-picker
          type="datetime"
          v-model="searchForm.startDate"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="选择日期时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="结束时间">
        <el-date-picker
          type="datetime"
          v-model="searchForm.endDate"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="选择日期时间">
        </el-date-picker>
      </el-form-item>
      <el-button type="primary" size="small" @click="search">查询</el-button>
      <el-button size="small" @click="clear">清空</el-button>
    </el-form>
    <el-table v-loading="loading" :data="dataList" border size="small"
      :header-cell-style="{background: '#41A1FF', color: '#ffffff'}"
      height="calc(100vh - 260px)">
      <el-table-column type="index" label="序号" width="80"></el-table-column>
      <el-table-column label="用户名" prop="username" width="130"></el-table-column>
      <el-table-column label="创建时间" prop="createTime" width="160"></el-table-column>
      <el-table-column label="IP地址" prop="ip"></el-table-column>
      <el-table-column label="URL地址" prop="url"></el-table-column>
      <el-table-column label="描述" prop="description"></el-table-column>
    </el-table>
    <div style="margin-top: 1em; text-align: center;">
      <el-pagination
        :page-sizes="[20, 50, 100, 200]"
        :page-size="pageSize"
        :current-page="page"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { getUserLog } from '@/api/userLog'
import { cleanParams } from '@/utils/index'
export default {
  data() {
    return {
      searchForm: {
        username: '',
        ip: '',
        url: '',
        startDate: null,
        endDate: null
      },
      dataList: [],
      page: 1,
      pageSize: 20,
      total: 0,
      loading: false
    }
  },
  mounted() {
    this.search()
  },
  methods: {
    search() {
      if (this.page === 1) {
        this.getData()
      } else {
        this.handleCurrentChange(1)
      }
    },
    clear() {
      this.searchForm = {
        username: '',
        ip: '',
        url: '',
        startDate: null,
        endDate: null
      }
      this.search()
    },
    getData() {
      const params = cleanParams(this.searchForm)
      params.pageSize = this.pageSize
      params.pageNum = this.page
      this.loading = true
      getUserLog(params).then(res => {
        if (res.code === '000000' && res.data.list) {
          this.dataList = res.data.list
          this.total = res.data.total
        } else {
          this.total = 0
          this.dataList = []
        }
        this.loading = false
      }).catch(() => {
        this.total = 0
        this.dataList = []
        this.loading = false
      })
    },
    handleSizeChange(size) {
      this.pageSize = size
      this.getData()
    },
    handleCurrentChange(page) {
      this.page = page
      this.getData()
    }
  }
}
</script>
